<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterProducts">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('eshopSubscriptionProduct.id')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.name" id="filter_name"
                         :label="$t('eshopSubscriptionProduct.name')"></app-input>
            </div>
            <div class="col-lg-2">
              <app-select
                v-model="filter.enabled"
                :options="enabledTypeValues"
                :label="$t('eshopSubscriptionProduct.enabled')"
                id="filter_enabled"
                noEmptyValue
              >
              </app-select>
            </div>
            <div class="col-lg-3">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('eshopSubscriptionProduct.filter.search') }}
              </button>
              <button class="btn btn-success pull-right m-t-30" data-test="product_reset_filter"
                      @click.prevent="resetFilters">
                {{ $t('eshopSubscriptionProduct.filter.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import ProductFilterModel from '../../model/eshop/SubscriptionProductFilter'
import EnabledTypeMixin from '../mixins/valueObject/eshop/EnabledTypeMixin'

export default {
  name: 'ProductFilter',
  mixins: [EnabledTypeMixin],
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(ProductFilterModel),
      advancedFilter: false
    }
  },
  components: {
    appInput: Input,
    appSelect: Select
  },
  methods: {
    filterProducts () {
      this.$store.commit('eshopSubscriptionProduct/setPage', 1)
      this.$store.commit('eshopSubscriptionProduct/setFilter', this.filter)
      this.$store.dispatch('eshopSubscriptionProduct/fetch')
    },
    resetFilters () {
      this.filter = this._.cloneDeep(ProductFilterModel)
      this.filterProducts()
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['eshopSubscriptionProduct/filter']
  }
}
</script>

<style lang="scss">

</style>
