export const ENABLED_TYPE_NONE = 'none'
export const ENABLED_TYPE_YES = '1'
export const ENABLED_TYPE_NO = '0'

export const ENABLED_TYPE_DEFAULT = ENABLED_TYPE_NONE

export default {
  computed: {
    enabledTypeValues () {
      return [
        {
          id: ENABLED_TYPE_NONE,
          title: '---'
        },
        {
          id: ENABLED_TYPE_YES,
          title: this.$t('eshopSubscriptionProduct.yes')
        },
        {
          id: ENABLED_TYPE_NO,
          title: this.$t('eshopSubscriptionProduct.no')
        }
      ]
    }
  },
  methods: {
    enabledTypeById (id) {
      var item = id
      this.enabledTypeValues.forEach(function (enabled) {
        if (id === enabled.id) {
          item = enabled
        }
      })
      return item
    }
  }
}
